export default function PrivacyVerklaring() {
  return (
    <main className="py-10">
      <div className="flex flex-col items-center">
        <h1 className="text-white text-4xl font-normal mb-4">
          PRIVACYVERKLARING
        </h1>

        <div className="text-white w-full md:w-2/3 lg:w-1/2  flex-1">
          <p>
            Deze privacyverklaring is voor het laatst bijgewerkt op maart 7,
            2023 en is van toepassing op burgers en wettelijk permanente
            inwoners van de Europese Economische Ruimte.
          </p>
          <br />
          <p>
            Met ingang van de nieuwe Algemene Verordening Gegevensbescherming
            (AVG) hierna heeft Leeuw Bier Oktoberfest de plicht om u te
            informeren welke persoonsgegevens door ons verzameld worden en
            waarom. Leeuw Bier Oktoberfest hecht waarde aan de privacy van
            bezoekers, vrijwilligers, artiesten en medewerkers en draagt er zorg
            voor dat verstrekte gegevens vertrouwelijk worden behandeld.{` `}
          </p>
          <br />

          <p>
            In dit privacystatement komt de verwerking van deze persoonsgegevens
            aan bod. Het statement is onderhevig aan wijzigingen die voort
            kunnen komen uit organisatorische of politieke ontwikkelingen,
            daarom is aan te raden het document zo nu en dan te raadplegen om op
            de hoogte te blijven van eventuele veranderingen.
          </p>
          <br />

          <p>
            Leeuw Bier Oktoberfest kan persoonsgegevens over u verwerken,
            doordat u gebruik maakt van de diensten van Leeuw Bier Oktoberfest,
            en/of omdat u deze zelf bij het invullen van een contactformulier op
            de website aan Leeuw Bier Oktoberfest verstrekt. Leeuw Bier
            Oktoberfest kan de volgende persoonsgegevens verwerken:
          </p>
          <br />

          <ul className="list-disc">
            <li>Voor- en achternaam</li>
            <li>Adresgegevens</li>
            <li>Telefoonnummer</li>
            <li>E-mailadres</li>
            <li>
              Betalings- en factuurgegevens wanneer een ticket wordt gekocht
            </li>
            <li>IP-adres</li>
          </ul>
          <br />

          <h2>
            WELKE PERSOONSGEGEVENS VERZAMELT Leeuw Bier Oktoberfest EN HOE
            WORDEN DEZE VERWERKT?
          </h2>
          <br />

          <p>
            TICKETS
            <br />
            Bij aankoop van festivalkaarten wordt door derden (onze
            ticketprovider) om persoonsgegevens gevraagd zoals naam, adres,
            e-mail, geboortedatum en woonplaats. Leeuw Bier Oktoberfest gebruikt
            de verkregen gegevens om inzicht te krijgen in de doelgroep van het
            evenement en voor interne administratie, niet om de individuele
            bezoekers te identificeren.
          </p>
          <br />

          <p>
            WEBSITE
            <br />
            Surfgegevens kunnen door Leeuw Bier Oktoberfest worden verzameld om
            het bezoek en gebruik van de website te analyseren en optimaliseren.
            Deze gegevens worden voor zover mogelijk anoniem gemaakt. Op de
            website van Leeuw Bier Oktoberfest zijn geen cookies geïnstalleerd,
            wel wordt er gebruik gemaakt van Google Analytics om inzicht te
            krijgen in de gebruiksvriendelijkheid van de website. Uitsluitend
            ter benutting van deze dienst van Google wordt deze informatie
            inclusief IP-adres aan Google verstrekt.
          </p>
          <br />

          <h2>NIEUWSBRIEF</h2>
          <p>
            Leeuw Bier Oktoberfest verstuurt geregeld nieuwsbrieven naar
            abonnees die zich hebben aangemeld op de website of zich hebben
            ingeschreven bij de aankoop van een festivalticket. Daarbij is
            toestemming gegeven om de verstrekte gegevens op te slaan met als
            doel op de hoogte te blijven van nieuws en ontwikkelingen rondom het
            evenement. Ontvangers van de nieuwsbrief kunnen zich op elk gewenst
            moment uitschrijven via de unsubscribe-link in de nieuwsbrief.
          </p>
          <br />

          <h2>BEELD- EN GELUIDSOPNAMEN VOOR, TIJDENS EN NA HET EVENEMENT</h2>
          <p>
            Ter promotie van het evenement kan Leeuw Bier Oktoberfest
            registraties maken van bezoekers, vrijwilligers, artiesten en
            medewerkers. Deze opnames worden mogelijk gedeeld via verschillende
            mediakanalen, zonder dat Leeuw Bier Oktoberfest of mediapartners een
            vergoeding aan de bezoekers, vrijwilliger, artiest of medewerker
            verschuldigd is.
          </p>
          <br />

          <h2>BEWAARTERMIJN</h2>
          <p>
            Leeuw Bier Oktoberfest bewaart persoonsgegevens niet langer dan
            noodzakelijk voor de uitvoering van overeenkomsten en ieder geval
            niet langer dan twee jaar indien er geen overeenkomst met u tot
            stand komt.{` `}
          </p>
          <br />

          <p>
            Leeuw Bier Oktoberfest vindt het belangrijk dat bezoekers het idee
            hebben dat er op een juiste manier met de door hen verstrekte
            persoonsgegevens wordt omgegaan. Als bezoekers de indruk krijgen dat
            gegevens niet goed beschermd zijn kan er contact opgenomen worden
            met{` `}
            <a href="mailto:event@leeuwbieroktoberfest.com">
              contact@leeuwbieroktoberfest.com
            </a>
          </p>
          <br />

          <h2>KLACHTEN</h2>
          <p>
            Bij klachten vragen kunt u ten alle tijden contact met ons opnemen
            via{` `}
            <a href="mailto:event@leeuwbieroktoberfest.com">
              contact@leeuwbieroktoberfest.com
            </a>
            {` `}
            wij proberen graag met u tot een oplossing te komen.
          </p>
          <br />

          <h2>WIJZINGINGEN PRIVACY STATEMENT</h2>
          <p>
            Leeuw Bier Oktoberfest behoudt zich het recht voor om wijzigingen
            aan te brengen in dit Privacy Statement. Gebruikers worden
            aangemoedigd om regelmatig zelf het privacy beleid van Leeuw Bier
            Oktoberfest te lezen ten aanzien van wijzigingen. Indien er
            belangrijke wijzigingen plaatsvinden aan ons privacy beleid, zullen
            we onze bezoekers, klanten en overige contacten daarover infomeren
            en bij ingrijpende wijzigingen vragen wij – indien nodig – ook
            toestemming.
          </p>
        </div>
      </div>
    </main>
  );
}
